import React from "react";

export const onRenderBody = ({ setHeadComponents, setPostBodyComponents }) => {
	setHeadComponents([
		<script key="gtag-js" async src="https://www.googletagmanager.com/gtag/js?id=GTM-KZ6KGSP"></script>
	]);

	setPostBodyComponents([
		<script
			key="gtm-script"
			dangerouslySetInnerHTML={{
				__html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','YOUR_GTM_ID');
        `
			}}
		/>
	]);
};
